section#baseball div.container-fluid {
    background: #54796d;
    padding: 25px;
    border-radius: 3px;
    color: #EEEEEE;
    text-align: center;
}

section#baseball .header {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 15px;
}

section#baseball {
    margin-top: 15px;
}

.teamName {
    font-size: 42px;
    font-weight: 700;
}

.teamRecord {
    position: relative;
    top: -5px;
    font-size: 30px;
    font-weight: 700;
}

section#baseball h1 {
    font-size: 60px;
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 30px;
    border-bottom: 10px solid #FFF;
    display: inline-block;
    margin-top: 10px
}

@media (max-width: 991.98px) {

    section#baseball h1 {
        font-size: 50px;
        border-bottom: 7px solid #FFF;
    }
}

@media (max-width: 767.98px) {

    section#baseball h1 {
        font-size: 45px;
        border-bottom: 6px solid #FFF;
        margin-top: 4px;
    }

    section#baseball .header {
        font-size: 25px;
    }

    .teamName {
        font-size: 30px;
    }

    .teamRecord {
        font-size: 25px;
    }
}

@media (max-width: 575.98px) {
    section#baseball h1 {
        font-size: 35px;
        border-bottom: 5px solid #FFF;
        margin-top: 2px;
    }
    
    section#baseball .header {
        font-size: 25px;
        margin-bottom: 25px;
    }

    .teamName.at {
        margin: 15px 0;
        display: block;
    }

    .teamName, .teamRecord {
        display: inline-block;
    }
    
    .teamRecord {
        position: static;
        margin-left: 10px
    }

    .teamRecord::before {
        content: "(";
    }

    .teamRecord::after {
        content: ")";
    }

}

@media (max-width: 475px) {
    section#baseball h1 {
        font-size: 25px;
    }
}