.socialLinks i {
    color: #000000;
    font-size: 35px;
    margin-right: 15px;
  }
  
  .socialLinks i:hover {
    color: #4D4D4D;
  }
  
  .socialLinks a:last-child i {
    margin-right: 0;
  }
  
  .socialLinks i.fa-linkedin {
    color: #0072b1;
  }
  
  .socialLinks i.fa-linkedin:hover {
    color: #0085CC;
  }