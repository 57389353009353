/* 
font-family: 'Roboto', sans-serif;
font-family: 'Roboto Slab', serif;
font-family: 'Ubuntu', sans-serif;
font-family: 'Ubuntu Condensed', sans-serif;
*/

/******** BODY ********/
body {
  background: #FFFFFF !important;
  font-family: 'Roboto', sans-serif !important;
}

.pageContainer {
  padding: 50px 75px;
}



@media (max-width: 767.98px) {
  .pageContainer {
    padding: 40px 50px;
  }
}

@media (max-width: 575.98px) {
  .pageContainer {
    padding: 30px 25px;
  }
}


/******** HEADER ********/
header {
  margin: 50px 0 85px 0;
}

header h1 {
  display: inline;
  font-weight: 700;
}

header h1 a {
  color: #000000;
  text-decoration: none;
}

header h1 a:hover {
  color: #000000;
}


/******** SOCIAL LINKS ********/
.socialLinks {
  margin-top: 20px;
}


/******** SECTIONS ********/
section {
  margin-top: 50px;
}

.sectionTitle {
  color: #4B5563;
  font-size: 16px;
  margin-top: 2px;
  padding-left: 50px;
  text-transform: uppercase;
}

.sectionContent {
  font-size: 18px;
}

.sectionContent ul {
  list-style-type: circle;
}

@media (max-width: 1399.98px) {
  .sectionTitle {
    padding-left: 0px;
  }
}

.container.mainContainer {
  margin-left: 0;
  margin-right: 0;
}


/******** PROJECTS ********/
section#projects .projectTitle {
  font-weight: bold;
  margin-bottom: 4px;
}

section#projects .projectTitle a {
  color: #212529;
  text-decoration: none;
}

section#projects .projectTitle a:hover {
  color: #434D56;
}

section#projects .projectDescription {
  color: #4B5563;
  margin-bottom: 30px;
}


/******** COURSEWORK ********/
section#coursework div.sectionContent p {
  margin-bottom: 5px;
}


/******** SKILLS ********/
section#skills .skillsGroupTitle {
  font-weight: bold;
  margin-bottom: 4px;
}

section#skills .skillsDescription {
  color: #4B5563;
  margin-bottom: 30px;
}